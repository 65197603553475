@import "typography";
$break-small: "only screen and (max-width : 574px)";
$base-font-family: "DIN Pro",
Helvetica,
Arial,
sans-serif;
$second-font-family: "Abril",
Helvetica,
Arial,
sans-serif;
$black:#212529;

.cardPreview {
    box-sizing: border-box;
    background: #eae7e2;
    position: relative;

    .bloc {
        border-bottom: 3px solid #ffc612;
        background-color: #fff;
    }

    .bloc-offer {
        font-size: 14px;
        text-align: center;
        max-width: 280px;
        width: 100%;
        margin: 0 auto;
        flex-basis: 0;
        flex-grow: 1;
        color: $black;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        box-shadow: 0 0 28px -4px rgba(82, 82, 82, 0.4);

        .bloc-offer-row {
            flex: 1 1 0;
        }

        &:link,
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: $black;
        }

        @media #{$break-small} {
            transition: none;
            margin-left: 0;
            margin-right: 0;
            flex-basis: auto;
            max-width: 100%;
        }

        @media #{$break-small} {
            max-width: 100%;
            margin-bottom: 20px;
        }

        .bloc-offer-cont-img {
            padding-top: 15px;
            height: 151px;

            img {

                height: auto;
            }
        }

        .img-pack {
            max-width: 200px;
        }

        .title {
            font-family: $base-font-family;
            padding-top: 15px;
            line-height: 26px;
            font-size: 22px;
            font-weight: normal;

            strong {
                text-transform: uppercase;
                font-weight: 900;
                font-size: 24px;
            }

            &.title-formule {
                margin-bottom: 0;

                strong {
                    font-size: 24px;
                }
            }
        }

        .price {
            margin: 30px auto 30px auto;
            max-width: 200px;
            font-size: 14px;
            font-weight: bold;
            padding-top: 10px;
            font-family: $base-font-family;

            .offer-hp-monthly {
                margin-left: -4px;
                font-family: $base-font-family;
            }

            .monthly {
                font-size: 55px;
                letter-spacing: -2px;
                line-height: 45px;

                .super {
                    font-size: 33px;
                    line-height: 0;
                    position: relative;
                    top: -23px;
                    left: 1px;
                }

                .price-complement {
                    font-size: 17px;
                    line-height: 30px;
                    letter-spacing: 0;
                }
            }

            .strict-month {
                margin-left: -17px;
                font-size: 17px;
                letter-spacing: normal;
                line-height: 0;
            }

            .offer-desc {
                margin-top: 15px;
                font-weight: bold;
                font-size: 17px;
                display: block;
            }
        }
    }

    .bloc-offer-liste {
        font-size: small;
        font-family: $base-font-family;
        padding: 0 15px;

        @media #{$break-small} {
            margin-top: 0;
            display: none;
            padding-top: 15px;
            padding-bottom: 30px;
        }

        ul {
            width: auto;
            margin-bottom: 0;
            display: inline-block;
            text-align: left;
            font-size: 18px; // 14px
            padding-left: 21px;
            list-style-image: url("./assets/img/offer_preview/checked.png");

            @media #{$break-small} {
                width: auto;
                margin: 0 auto;
            }
        }

        li {
            width: 100%;
            margin-bottom: 8px;
            line-height: 22px;
        }

        &.offer-liste-formule {
            ul {
                font-size: 14px; //14px
            }

            li {
                line-height: 1.2;
                max-width: 100%;
            }
        }
    }

    .separator {
        width: 70px;
        border: 1px solid #ffc612;
        border-radius: 2px;
        margin: 30px auto;

        @media #{$break-small} {
            display: none;
        }
    }

    .call-to-action {
        border: none;
        text-align: center;
        display: block;
        margin: auto;
        background-color: #ffc612;
        color: #fff;
        padding: 8px 6px;
        font-weight: 700;
        font-family: $base-font-family;
        font-size: 14px;
        text-transform: uppercase;
    }

    .button-container {
        padding-bottom: 28px;
    }

    .subscription-cta {
        max-width: 170px;
        width: 100%;
    }

    .means-payement {
        display: inline-block;
        max-width: 150px;
        width: 100%;
        height: 22px;
        background: url("./assets/img/offer_preview/means_payement.jpg") top center no-repeat;
        background-size: 100%;
        margin-bottom: 20px;
    }

    .ft-norm {
        font-weight: normal !important;
    }

    .ft-obl {
        font-style: oblique;
    }

}