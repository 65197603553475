.copyElement {
  display: inline;
}

.copyArea {
  display: none;
  position: relative;
  left: -10000px;
}

.copyInfo {
  position: absolute;
  margin-left: 5px;
}
