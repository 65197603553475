$font-family: 'DIN Pro';

// NORMAL NORMAL
@font-face {
    font-family: $font-family;
    src: url('./assets/fonts/DINPro/Regular/DINPro.eot');
    src: url('./assets/fonts/DINPro/Regular/DINPro.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Regular/DINPro.woff') format('woff'),
    url('./assets/fonts/DINPro/Regular/DINPro.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Regular/DINPro.svg#DINPro') format('svg'),
    url('./assets/fonts/DINPro/Regular/DINPro.eot?#iefix') format('embedded-opentype');
    font-weight: 400; // NORMAL
    font-style: normal;
}
// NORMAL ITALIC
@font-face {
    font-family: 'DINPro-Italic';
    src: url('./assets/fonts/DINPro/Regular/DINPro-Italic.eot');
    src: url('./assets/fonts/DINPro/Regular/DINPro-Italic.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Regular/DINPro-Italic.woff') format('woff'),
    url('./assets/fonts/DINPro/Regular/DINPro-Italic.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Regular/DINPro-Italic.svg#DINPro-Italic') format('svg'),
    url('./assets/fonts/DINPro/Regular/DINPro-Italic.eot?#iefix') format('embedded-opentype');
    font-weight: 400;
    font-style: italic;
}



// BOLD NORMAL
@font-face {
    font-family: $font-family;
    src: url("./assets/fonts/DINPro/Bold/DINPro-Bold.eot");
    src: url('./assets/fonts/DINPro/Bold/DINPro-Bold.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Bold/DINPro-Bold.woff') format('woff'),
    url('./assets/fonts/DINPro/Bold/DINPro-Bold.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Bold/DINPro-Bold.svg#DINPro-Bold') format('svg'),
    url('./assets/fonts/DINPro/Bold/DINPro-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: 700; // BOLD
    font-style: normal;
}
// BOLD ITALIC
@font-face {
    font-family: $font-family;
    src: url('./assets/fonts/DINPro/Bold/DINPro-BoldItalic.eot');
    src: url('./assets/fonts/DINPro/Bold/DINPro-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Bold/DINPro-BoldItalic.woff') format('woff'),
    url('./assets/fonts/DINPro/Bold/DINPro-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Bold/DINPro-BoldItalic.svg#DINPro-BoldItalic') format('svg'),
    url('./assets/fonts/DINPro/Bold/DINPro-BoldItalic.eot?#iefix') format('embedded-opentype');
    font-weight: 700;
    font-style: italic;
}

// LIGHT NORMAL
@font-face {
    font-family: $font-family;
    src: url('./assets/fonts/DINPro/Light/DINPro-Light.eot');
    src: url('./assets/fonts/DINPro/Light/DINPro-Light.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Light/DINPro-Light.woff') format('woff'),
    url('./assets/fonts/DINPro/Light/DINPro-Light.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Light/DINPro-Light.svg#DINPro-Light') format('svg'),
    url('./assets/fonts/DINPro/Light/DINPro-Light.eot?#iefix') format('embedded-opentype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: $font-family;
    src: url('./assets/fonts/DINPro/Light/DINPro-LightItalic.eot');
    src: url('./assets/fonts/DINPro/Light/DINPro-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Light/DINPro-LightItalic.woff') format('woff'),
    url('./assets/fonts/DINPro/Light/DINPro-LightItalic.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Light/DINPro-LightItalic.svg#DINPro-LightItalic') format('svg'),
    url('./assets/fonts/DINPro/Light/DINPro-LightItalic.eot?#iefix') format('embedded-opentype');
    font-weight: 200;
    font-style: italic;
}


// BLACK NORMAL
@font-face {
    font-family: $font-family;
    src: url('./assets/fonts/DINPro/Black/DINPro-Black.eot');
    src: url('./assets/fonts/DINPro/Black/DINPro-Black.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Black/DINPro-Black.woff') format('woff'),
    url('./assets/fonts/DINPro/Black/DINPro-Black.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Black/DINPro-Black.svg#DINPro-Black') format('svg'),
    url('./assets/fonts/DINPro/Black/DINPro-Black.eot?#iefix') format('embedded-opentype');
    font-weight: 900; // BLACK
    font-style: normal;
}
// BLACK ITALIC
@font-face {
    font-family: $font-family;
    src: url('./assets/fonts/DINPro/Black/DINPro-BlackItalic.eot');
    src: url('./assets/fonts/DINPro/Black/DINPro-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Black/DINPro-BlackItalic.woff') format('woff'),
    url('./assets/fonts/DINPro/Black/DINPro-BlackItalic.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Black/DINPro-BlackItalic.svg#DINPro-BlackItalic') format('svg'),
    url('./assets/fonts/DINPro/Black/DINPro-BlackItalic.eot?#iefix') format('embedded-opentype');
    font-weight: 900;
    font-style: italic;
}

/*
 ################################################################
 ##################### CONDENSED FONT ###########################
 ################################################################
 */

// CONDENSED NORMAL
@font-face {
    font-family: 'DIN Pro Condensed';
    src: url('./assets/fonts/DINPro/Condensed/DINPro-CondensedRegular.eot');
    src: url('./assets/fonts/DINPro/Condensed/DINPro-CondensedRegular.woff2') format('woff2'),
    url('./assets/fonts/DINPro/Condensed/DINPro-CondensedRegular.woff') format('woff'),
    url('./assets/fonts/DINPro/Condensed/DINPro-CondensedRegular.ttf') format('truetype'),
    url('./assets/fonts/DINPro/Condensed/DINPro-CondensedRegular.svg#DINPro-CondensedRegular') format('svg'),
    url('./assets/fonts/DINPro/Condensed/DINPro-CondensedRegular.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

